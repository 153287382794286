<template>
    <a v-html="text" :class="customClasses" :href="url" :target="target"></a>
</template>

<script>
export default {
    name: "LinkButton",
    props: {
        customClasses: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        target: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        
    },
}
</script>