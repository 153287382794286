<template>
    <div class="title-banner" :class="customClasses">
        <h2 class="title" v-html="title"></h2>
        <div class="bar"></div>
    </div>
</template>
<script>
export default {
    name: "TitleSection",
    props: {
        title: {
            type: String,
            required: true,
        },
        customClasses: {
            type: String,
            required: false,
        }
    },
}
</script>