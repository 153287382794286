<template>
  <header class="header position-fixed">
    <Navbar customClasses="bg-transparent-dark py-4"></Navbar>
  </header>
  <router-view />
  <Footer></Footer>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      headerLogo: require("@/assets/img/general/mybrand-logo.png"),
      footerLogo: require("@/assets/img/general/mybrand-logo-2.png"),
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/app.scss";
</style>
