<template>
    <!--WebSite oiptions-->
        <ul class="website-options" :class="customClasses">
          <li class="nav-item website-item">
            <a class="nav-link social-network-link"
            v-bind:class="{ active : heroLink }" 
            v-on:click="activateHero"
            href="#HeroSection">
              Inicio
            </a>
          </li>
          <li class="nav-item website-item">
            <a class="nav-link social-network-link"
            v-bind:class="{ active : aboutUsLink }"
            @click="activateAboutUs" 
            href="#AboutUsSection">
              Quienes Somos
            </a>
          </li>
          <li class="nav-item website-item">
            <a class="nav-link social-network-link"
            v-bind:class="{ active : catalogLink }"
            @click="activateCatalog"
            href="#Catalog">
              Cátalogo
            </a>
          </li>
          <li class="nav-item website-item">
            <a class="nav-link social-network-link"
            v-bind:class="{ active : serviceLink }"
            @click="activateServices" 
            href="#Services">
              Servicios
            </a>
          </li>
          <li class="nav-item website-item">
            <a class="nav-link social-network-link"
            v-bind:class="{ active : contactLink }"
            @click="activateContact" 
            href="#Contact">
              Contacto
            </a>
          </li>
        </ul>
</template>

<script>
export default {
    name: "NavLinks",
    props: {
        customClasses: {
            type: String,
            required: true,
        }
    },
    data(){
      return {
        heroLink: true,
        aboutUsLink: false,
        catalogLink: false,
        serviceLink: false,
        contactLink: false,
      }
    },
    methods: {
      activateHero(){
        this.heroLink = true;
        this.aboutUsLink = false;
        this.catalogLink = false;
        this.serviceLink = false;
        this.contactLink = false;
      },
      activateAboutUs(){
        this.heroLink = false;
        this.aboutUsLink = true;
        this.catalogLink = false;
        this.serviceLink = false;
        this.contactLink = false;
      },
      activateCatalog(){
        this.heroLink = false;
        this.aboutUsLink = false;
        this.catalogLink = true;
        this.serviceLink = false;
        this.contactLink = false;
      },
      activateServices(){
        this.heroLink = false;
        this.aboutUsLink = false;
        this.catalogLink = false;
        this.serviceLink = true;
        this.contactLink = false;
      },
      activateContact(){
        this.heroLink = false;
        this.aboutUsLink = false;
        this.catalogLink = false;
        this.serviceLink = false;
        this.contactLink = true;
      },
    },
}
</script>