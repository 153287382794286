<template>
  <div class="home">

    <!-- Hero Section -->
    <section class="page-section bg-dark hero-section" id="HeroSection">
      <div
        id="HeroCarousel"
        class="carousel slide hero-carousel"
        data-bs-ride="carousel"
        data-bs-interval="6000"
      >
        <div class="carousel-indicators hero-indicators">
          <button
            type="button"
            v-for="(hero, index) in heros"
            :key="hero.id"
            data-bs-target="#HeroCarousel"
            :data-bs-slide-to="index"
            class="hero-carousel-button"
            :class="{ active: index === 0 }"
            v-bind:aria-current="{ true: index === 0 }"
            :aria-label="'Slide ' + hero.id"
          ></button>
        </div>
        <div class="carousel-inner hero-carousel-container">
          <div
            class="carousel-item hero-item"
            :class="{ active: index === 0 }"
            v-for="(hero, index) in heros"
            :key="hero.id"
          >
            <div
              class="hero-item-bg bg-image"
              :style="{ 'background-image': 'url(' + hero.image + ')' }"
            >
              <div class="hero-item-content">
                <Title :text="hero.title" customClasses="hero-title mb-5">
                </Title>
                <LinkButton
                  customClasses="btn btn-transparent-dark px-5 py-2 hero-button"
                  target="_self"
                  :url="hero.link"
                  :text="hero.buttonText"
                >
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- About Us Section -->
    <section class="page-section about-us-section container-fluid"
      id="AboutUsSection">
      <TitleSection title="Quienes Somos"></TitleSection>
      <div class="row justify-content-center background-section-row">
        <div class="col-1 col-md-2 bg-white"></div>
        <div class="col-11 col-md-10 bg-mybrand-black"></div>
      </div>
      
      <div class="row justify-content-center content-section-row">
        <div class="col-10 col-md-5 content-column my-md-auto">
          <img :src="tShirt" alt="" class="img-fluid" />
        </div>
        <div class="col-10 col-md-5 content-column my-md-auto">
            <p class="text-content mb-4 mb-lg-5">
              Somos la solución a tus proyectos de estampado, 
              <span>manejamos una gran y amplia gama de servicios 
                así como de colores, acabados y materiales.</span>
            </p>
            <p class="text-content text-mybrand-red mb-5">
              ¡Conocemos seguro tenemos la mejor opción para hacer realidad tus proyectos!
            </p>
            <LinkButton 
              customClasses="btn btn-mybrand-link px-5" 
                target="_self"
                url="#Catalog"
                text="Ver Catálogo &nbsp; <i class='fas fa-arrow-right'></i>">
            </LinkButton>
        </div>
      </div>
    </section>

    <!-- Catalog Section -->
    <section class="page-section catalog-section bg-mybrand-gray-3 py-5" 
    id="Catalog">
      
      <Title 
        customClasses="title-section text-white mt-5" 
        text="Conoce nuestro cátalogo<span class='text-mybrand-red'>.</span>">
      </Title>
      <div class="container-fluid content-container mt-4">
        <div class="row justify-content-center catalog-row py-5">
          <div class="col-11 col-sm-6 col-md-3 col-lg-2 mb-3 mb-lg-0 px-md-0 catalog-column" 
              v-for="item in catalog" :key="item.id">
            <div class="card catalog-card">
              <div class="card-body text-center catalog-card-body py-4">
                <h6 class="card-title">{{ item.title }}</h6>
                <img :src="item.image" alt="" 
                      class="img-fluid catalog-card-image mx-auto">
                <img :src="item.image2" alt="" 
                class="img-fluid catalog-card-image-2 mx-auto">
                <a href="#" class="btn btn-catalog-link px-3" target="_self">
                  Ver Catálogo &nbsp; <i class="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TitleSection title="Catálogo"></TitleSection>
    </section>

    <!-- Services Section -->
    <section class="page-section services-section container-fluid" 
    id="Services">

      <TitleSection title="Servicios"></TitleSection>
      <!-- Background Container-->
      <div class="row justify-content-center background-section-row">
        <div class="col-10 col-md-11 bg-white">
        </div>
        <div class="col-2 col-md-1 bg-mybrand-black">
        </div>      
      </div>

      <!-- Content Container-->
      <div class="row justify-content-center content-section-row">
        <div class="col-11 col-md-8 col-md-10 pt-5 pb-3 column-title">
          <Title customClasses="title-section" 
            text="Servicios<span class='text-mybrand-red'>.</span>">
          </Title>
        </div>
        <div class="col-12 px-0 section-column py-4">
          <div class="services-container" 
              :style="{ 'transform' : this.servicesTranslate }">
            <div class="card service-card bg-image" 
                v-for="service in services" :key="service.id"
                :style="{ 'background-image' : 'url(' + service.image + ')' }">
              <div class="card-body service-card-body">
                <h5 class="service-card-title">{{ service.title }}</h5>
                <p class="service-card-description mb-0">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11 col-md-4 d-none d-md-block">

        </div>
        <div class="col-11 col-md-4 text-center d-none d-md-block">
          <div class="service-indicator-container">
            <span class="services-indicator me-2"
            :class="{ 'active-indicator' : prevServiceIndicator}" 
            id="prevServiceIndicator"
            @click="prevServiceCard"></span>
            <span class="services-indicator ms-2"
            :class="{ 'active-indicator' : nextServiceIndicator}" 
            id="nextServiceIndicator"
            @click="nextServiceCard"></span>
          </div>
        </div>
        <div class="col-11 col-md-4 text-center d-none d-md-block">
          <button 
            class="btn btn-outline-danger me-2" 
            id="PrevServiceCard"
            @click="prevServiceCard">
            <i class="fas fa-angle-left"></i>
          </button>
          <button 
            class="btn btn-outline-danger ms-2" 
            id="NextServiceCard"
            v-on:click="nextServiceCard">
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
      </div>
    </section>


    <ContactSection></ContactSection>

    
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import LinkButton from "@/components/LinkButton.vue";
import ContactSection from "@/sections/ContactSection.vue";
import TitleSection from "@/components/TitleSection.vue";

export default {
  name: "Home",
  components: {
    Title,
    LinkButton,
    ContactSection,
    TitleSection
  },
  data() {
    return {
      heros: [
        {
          id: 1,
          title:
            'Hagamos de <br /> tu proyecto <span style="color: #F24040">algo grande.</span>',
          image: require("@/assets/img/hero/hero-image-1.jpg"),
          buttonText: 'Contáctanos &nbsp; <i class="fas fa-arrow-right"></i>',
          link: "#Contact",
        },
        {
          id: 2,
          title:
            'Hagamos realidad <br /> <span style="color: #F24040">tus proyectos</span>',
          image: require("@/assets/img/hero/hero-image-2.jpg"),
          buttonText: 'Contáctanos &nbsp; <i class="fas fa-arrow-right"></i>',
          link: "#Contact",
        },
      ],
      tShirt: require('@/assets/img/about_us/t-shirt.png'),
      catalog: [
        {
          id: 1,
          title: "Artículos Promocionales",
          image: require('@/assets/img/catalog/articulos-promocionales.png'),
          image2: require('@/assets/img/catalog/red-promocional.png'),
          link: "",
        },
        {
          id: 2,
          title: "Termos",
          image: require('@/assets/img/catalog/termos.png'),
          image2: require('@/assets/img/catalog/red-termo.png'),
          link: "",
        },
        {
          id: 3,
          title: "Gorras",
          image: require('@/assets/img/catalog/gorras.png'),
          image2: require('@/assets/img/catalog/red-cap.png'),
          link: "",
        },
        {
          id: 4,
          title: "Uniformes y ropa",
          image: require('@/assets/img/catalog/uniformes-y-ropa.png'),
          image2: require('@/assets/img/catalog/red-uniform.png'),
          link: "",
        },
        {
          id: 5,
          title: "Listones",
          image: require('@/assets/img/catalog/listones.png'),
          image2: require('@/assets/img/catalog/red-liston.png'),
          link: "",
        },
        {
          id: 6,
          title: "Mantelería",
          image: require('@/assets/img/catalog/manteleria.png'),
          image2: require('@/assets/img/catalog/red-manteleria.png'),
          link: "",
        }
      ],
      services: [
        {
          id: 1,
          title: "Impresión DTG",
          image: require('@/assets/img/services/dtg.jpg'),
          description: "",
        },
        {
          id: 2,
          title: "Bordado",
          image: require('@/assets/img/services/bordado_negro.jpg'),
          description: "",
        },
        {
          id: 3,
          title: "Corte Vinil",
          image: require('@/assets/img/services/vinil_negro.jpg'),
          description: "",
        },
        {
          id: 4,
          title: "Serigrafía",
          image: require('@/assets/img/services/serigrafia_negro.jpg'),
          description: "Contamos con la más alta calidad en servicio de serigrafía.",
        },
        {
          id: 5,
          title: "Sublimado",
          image: require('@/assets/img/services/subli_negro.jpg'),
          description: "",
        },
        {
          id: 6,
          title: "Técnicas para gorras",
          image: require('@/assets/img/services/gorras.jpg'),
          description: "",
        },
        {
          id: 7,
          title: "Grabado Laser",
          image: require('@/assets/img/services/laser.jpg'),
          description: "",
        },
        {
          id: 8,
          title: "Impresión",
          image: require('@/assets/img/services/impresion.jpg'),
          description: "",
        },
        {
          id: 9,
          title: "Rotulado",
          image: require('@/assets/img/services/rotulacion.jpg'),
          description: "",
        }
      ],
      servicesTranslate: "translateX(0)",
      prevServiceIndicator: true,
      nextServiceIndicator: false,
    };
  },
  methods: {
    prevServiceCard(){
      this.servicesTranslate = "translateX(0)";
      this.prevServiceIndicator = true;
      this.nextServiceIndicator = false;
    },
    nextServiceCard(){
      this.servicesTranslate = "translateX(-28.5713%)";
      this.nextServiceIndicator = true;
      this.prevServiceIndicator = false;
    },  
  }
};
</script>
