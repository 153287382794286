<template>
    <h4 v-html="text" :class="customClasses"></h4>
</template>

<script>
export default {
    name: "Title",
    props: {
        customClasses: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    },
}
</script>

<style scoped>

</style>
