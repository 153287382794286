<template>
    <!-- Social media Options -->
        <ul class="social-network-options" :class="customClasses">
            <li class="nav-item social-network-item">
                <a class="nav-link social-network-link" href="https://www.facebook.com/mybrand.la/" target="_blank">
                    <img :src="facebookLogo" alt="" class="social-network-logo" />
                </a>
            </li>
            <li class="nav-item social-network-item">
                <a class="nav-link social-network-link" href="https://www.instagram.com/mybrand_la/" target="_blank">
                    <img :src="instagramLogo" alt="" class="social-network-logo" />
                </a>
            </li>
        </ul>   
</template>

<script>
export default {
    name: "SocialMediaLinks",
    props: {
        customClasses: {
            type: String,
            required: true,
        }    
    },
    data() {
        return {
            facebookLogo: require('@/assets/img/social_media/face.png'),
            instagramLogo: require('@/assets/img/social_media/Insta.png'),
        }
    }
}
</script>