<template>
  <nav
    class="
      navbar navbar-expand-lg navbar-dark
      main-nav
    "
    :class="customClasses"
    id="MainNavbar"
  >
    <div class="container-fluid navbar-container">
      <a class="navbar-brand logo-link" href="#">
        <img :src="siteLogo" alt="" class="site-logo ps-2 ps-md-4" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse site-options" id="navbarNav">
        
        <NavLinks customClasses="navbar-nav ms-lg-auto pe-lg-5">
        </NavLinks>

        <SocialMediaLinks customClasses="navbar-nav ms-lg-auto pe-2 pe-md-4">
        </SocialMediaLinks>
      </div>
    </div>
  </nav>
</template>

<script>

import NavLinks from "@/components/NavLinks.vue";
import SocialMediaLinks from "@/components/SocialMediaLinks.vue";

export default {
  name: "Navbar",
  components: {
    NavLinks,
    SocialMediaLinks,  
  },
  props: {
    customClasses: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      siteLogo: require('@/assets/img/general/mybrand-logo.png'),
    };
  },
};
</script>