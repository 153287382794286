<template>
    <footer class="py-5 px-3">
        <div class="row justify-content-center footer-row">
            <div class="col-11 col-md-3 col-lg-3 text-center text-md-start my-md-auto footer-column">
                <img :src="require('@/assets/img/general/mybrand-logo-2.png')" 
                alt="" 
                class="footer-logo mb-4 mb-md-0 ms-md-5">
            </div>
            <div class="col-11 col-md-6 col-lg-6 text-center text-md-start my-md-auto footer-column">
                <NavLinks customClasses="nav mb-4 mb-md-0"></NavLinks>
            </div>
            <div class="col-11 col-md-3 col-lg-3 text-center my-md-auto footer-column">
                <span class="social-network-text mb-4 mb-md-0">¡Siguenos en nuestras redes!</span>
                <SocialMediaLinks customClasses="nav"></SocialMediaLinks>
            </div>
        </div>
    </footer>
</template>

<script>

import NavLinks from "@/components/NavLinks.vue";
import SocialMediaLinks from "@/components/SocialMediaLinks.vue";

export default {
    name: "Footer",
    components: {
        NavLinks,
        SocialMediaLinks,
    }
}
</script>