<template>
    <!-- Contact Section -->
    <section class="page-section contact-section container-fluid" 
    id="Contact">
      <div class="row justify-content-center contact-row">
        <div class="col-12 col-md-6 col-lg-7 bg-mybrand-black contact-column">
          <div class="content-column bg-image py-5" 
                :style="{ 'background-image': 'url(' + contactImage + ')' }">
            <Title customClasses="title-section ps-5 my-5" 
                  text="Contactanos<span class='text-mybrand-red'>.</span>">
            </Title>
            <div class="contact-data px-3 ms-md-3 ms-xl-5">
              <div v-for="contact in contactData" :key="contact.id" 
              class="row justify-content-center contact-item mb-5">
                <div class="col-2 col-sm-2 col-xl-1 text-center">
                  <img v-bind:src="contact.icon" alt="" 
                  class="img-fluid contact-icon">
                </div>
                <div class="col-8 col-sm-8 col-xl-9">
                  <p class="name mb-0">{{ contact.name }}</p>
                  <p class="data mb-0">{{ contact.data }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 contact-column bg-mybrand-black p-5">
            <!--Contact form-->
          <form class="contact-form bg-white p-5 text-center" 
                @submit.prevent="sendData">
            <Title customClasses="form-title mb-4 text-start" 
            text="Platicanos <br /> tu proyecto<span class='text-mybrand-red'>.</span>">
            </Title>
            <input type="text" class="contact-input mb-5" name="name" 
                placeholder="Nombre" v-model="contact.name" />
            <input type="email" class="contact-input mb-5" name="email" 
                placeholder="Correo" v-model="contact.email" />
            <textarea name="message" id="contactMessage"  
                class="contact-textarea mb-5" placeholder="Mensaje"
                v-model="contact.message"></textarea>
            <button type="submit" class="btn btn-outline-danger rounded-0 px-5 mx-auto contact-button mb-5">
              Enviar &nbsp; <i class="fas fa-arrow-right"></i>
            </button>
          </form>
        </div>
      </div>
      <FormAlert :showAlert="showAlert" @hideAlert=hideAlert v-show="showAlert"></FormAlert>
    </section>
</template>

<script>

import Title from "@/components/Title.vue";
import FormAlert from "@/components/FormAlert.vue";
import axios from "axios";

export default {
    name: "ContactSection",
    components: {
        Title,
        FormAlert,
    },
    data(){
        return {  
            contactImage: require('@/assets/img/contact/map.png'),
            contactData: [
                {
                id: 1,
                name: "Whatsapp",
                icon: require('@/assets/img/contact/icons/whatsapp.png'),
                data: "33 3985 9293",
                },
                {
                id: 2,
                name: "Teléfono",
                icon: require('@/assets/img/contact/icons/phone.png'),
                data: "33 3985 9293",
                },
                {
                id: 3,
                name: "Correo",
                icon: require('@/assets/img/contact/icons/message.png'),
                data: "Hola@mybrand.com",
                },
            ],
            contact: {
                name: '',
                email: '',
                message: '',
            },
            showAlert: false,
        }
    },
    methods: {
        sendData(){
            //Validating Fields
            if(this.contact.name.trim() === '' ||  this.contact.email.trim() === '' || this.contact.message.trim() === '')
            {
                alert("Porfavor llene todos los campos.");
                return;
            }

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            //Adding form fields to an object
            let data = new FormData();
            data.append('name', this.contact.name);
            data.append('email', this.contact.email);
            data.append('message', this.contact.message);

            //sending data to via post
            axios.post('/static/sendMail.php', data, config)
                .catch((err) => console.log(err));

            //Cleaning form fields
            this.contact.name = '';
            this.contact.email = '';
            this.contact.message = '';

            console.log(data);
            this.showAlert = true;
        },
        showModal() {
            this.showAlert = true;
        },
        hideAlert(){
            this.showAlert = false;
        }
    }
}
</script>