<template>
    <div class="form-alert" :class="{'active-alert' : showAlert }" id="formAlert">
        <div class="form-alert-content">
            <span id="closeFormAlert" class="close-btn" @click="closeAlert">
                <i class="fas fa-times"></i>
            </span>
            <p class="message">¡Tu mensaje ha sido enviado con éxito!</p>
        </div>
    </div>
</template>

<script>

export default {
    name: "FormAlert",
    props: {
        showAlert: {
            type: Boolean,
            required: true,
        }
    },
    methods: {
        closeAlert() {
            this.$parent.hideAlert();
        }
    },
}
</script>

<style scoped>

</style>